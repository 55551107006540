<template>
  <Browse
    :columns="[
      'inflow',
      'sequence',
      'product',
      'quantity',
    ]"
    :formats="{
      sequence: 'Integer4',
      quantity: 'Decimal',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="inflow_items"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
